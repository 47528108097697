import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DrawerMenuIcon from "../../../assets/icons/Menu.svg";
import SiemensLogoWhite from "../../../assets/images/siemens-logo-white.svg";
import HistoryIcon from "@material-ui/icons/History";
import ForwardIcon from "@material-ui/icons/Forward";
import DoneAllIcon from "@material-ui/icons/DoneAllOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
import HomeIcon from "@material-ui/icons/Home";
import SecurityRoundedIcon from "@material-ui/icons/SecurityRounded";
import "../SideDrawer/SideDrawer.css";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";


const useStyles = makeStyles({
    list: {
        width: 300,
    },
    fullList: {
        width: "auto",
    },
    overrides: {},
});

export default function SideDrawer() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [drawerState, setDrawerState] = React.useState({
        right: false,
    });

    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setDrawerState({ ...drawerState, [anchor]: open });
    };

    const sideDrawerItems =
        (userDetails.roleId == 1 ? [
            {
                icon: <HomeIcon />,
                title: "Home",
                link: "/",
            },
            {
                icon: <SecurityRoundedIcon />,
                title: "Admin",
                link: "/admin",
            },
            { icon: <HistoryIcon />, title: "History", link: "/history" },
            {
                icon: <ForwardIcon />,
                title: "Courses in Progress",
                link: "/progress",
            },

            {
                icon: <DoneAllIcon />,
                title: "Completed Courses & Certificates",
                link: "/download",
            },
        ] : userDetails.roleId == 2 ?
            [
                {
                    icon: <HomeIcon />,
                    title: "Home",
                    link: "/",
                },
                {
                    icon: <SecurityRoundedIcon />,
                    title: "Partner Admin",
                    link: "/PartnerAdmin",
                },
                { icon: <HistoryIcon />, title: "History", link: "/history" },
                {
                    icon: <ForwardIcon />,
                    title: "Courses in Progress",
                    link: "/progress",
                },

                {
                    icon: <DoneAllIcon />,
                    title: "Completed Courses & Certificates",
                    link: "/download",
                },
            ] :
            [
                {
                    icon: <HomeIcon />,
                    title: "Home",
                    link: "/",
                },
                { icon: <HistoryIcon />, title: "History", link: "/history" },
                {
                    icon: <ForwardIcon />,
                    title: "Courses in Progress",
                    link: "/progress",
                },

                {
                    icon: <DoneAllIcon />,
                    title: "Completed Courses & Certificates",
                    link: "/download",
                },]);


    function logoutUser(auth, dispatch) {
        var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
        window.history.pushState({ path: newurl }, "", `/`);
        localStorage.clear(); sessionStorage.clear();
        caches.keys().then((names) => { names.forEach((name) => { caches.delete(name); }); });
        // if (auth !== undefined) {  //   //auth.removeUser();  
        //   // // dispatch(saveAccessTokenAction(''));  
        //   dispatch(storeUserDetailsAction(""));  
        // }  
        window.location.href = "/";
    }



    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === "top" || anchor === "bottom",
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Link to="/" className="d-flex jc-center side-drawer-logo">
                <img src={SiemensLogoWhite} alt="Siemens" className="official-logo" />
            </Link>
            <List className="side-drawer-menu-list">
                <ListItem>
                    <ListItemIcon className="side-drawer-icons">
                        <PersonIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={userDetails.firstName + " " + userDetails.lastName}
                    />
                </ListItem>
                {sideDrawerItems.map((item, index) => (
                    <Link to={item.link} key={item.title}>
                        <ListItem button>
                            <ListItemIcon className="side-drawer-icons">
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItem>
                    </Link>
                ))}
                <ListItem>
                    <ListItemIcon className="side-drawer-icons">
                        <IconButton onClick={(auth) => logoutUser(auth, dispatch)} className="logout-btn">

                            <ExitToAppIcon className="logout-icon" ontSize="medium"/>
                            <ListItemText
                                primary="Logout" className="logout-text"
                            />
                        </IconButton>
                    </ListItemIcon>

                </ListItem>
            </List>
            <div className="sideDrawer-footer-block">
                <p>© 2021 Siemens</p>
            </div>
        </div>
    );

    return (
        <React.Fragment>
            {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                    <img
                        src={DrawerMenuIcon}
                        alt="leap Menu"
                        className="leap-menu-icon"
                        onClick={toggleDrawer(anchor, true)}
                    />
                    <SwipeableDrawer
                        anchor={anchor}
                        open={drawerState[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
        </React.Fragment>
    );
}
