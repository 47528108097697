import React, { useState, useEffect } from "react";
import {
    Container,
    Grid,
    MenuItem,
    FormControl,
    Select,
    Button,
    InputLabel,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Cognisphere from "../../assets/images/Cognisphere.png";
import demoImage from "../../assets/images/others/BasicsOfControlProducts.jpg";
import { baseApiURL } from "../../Utilities/utility";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Loading from "../../SharedComponents/Elements/Loading/Loading";


export default function Progress() {
    const [category, setCategory] = useState([]);
    const [categoryValue, setCategoryValue] = useState(0);
    const [progressCourseDetails, setProgressCourseDetails] = useState([]);
    //const [progressCourseDetailsById, setProgressCourseDetailsById] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );

    const handleChange = (event) => {
        setCategoryValue(event.target.value);
        if (event.target.value != 0) {
            getCourseListbyId(event.target.value);
        } else {
            getCourseList();
        }
    };

    function getCategoryList() {
        //const token = localStorage.getItem('access_token');
        fetch(baseApiURL + "/Master/GetAssignedCategoryList?userId=" + userDetails.id + "&roleId=" + userDetails.roleId, {
            headers: { Authorization: "Bearer " + token },
        })
            .then((response) => response.json())
            .then((data) => {
                setCategory(data.result);
            })
            .catch((error) => console.log("Error:", error));
    }
    function getCourseList() {
        setIsLoader(true);
        //const token = localStorage.getItem('access_token');
        fetch(
            baseApiURL +
            "/Course/GetUserInProgressCourseList?UserId=" +
            userDetails.id + "&RoleId=" + userDetails.roleId,
            { headers: { Authorization: "Bearer " + token } }
        )
            .then((response) => response.json())
            .then((data) => {
                setIsLoader(false);
                setProgressCourseDetails(data.result);
            })
            .catch((error) => console.log("Error:", error));
    }

    function getCourseListbyId(categoryId) {
        setIsLoader(true);
    //const token = localStorage.getItem('access_token');
    fetch(
      baseApiURL +
        "/Course/GetUserInProgressCourseListByCategory?UserId=" +
        userDetails.id +
        "&CategoryId=" +
        categoryId + "&RoleId=" + userDetails.roleId,
      { headers: { Authorization: "Bearer " + token } }
    )
      .then((response) => response.json())
      .then((data) => {
          setIsLoader(false);
        setProgressCourseDetails(data.result);
      })
      .catch((error) => console.log("Error:", error));
  }

    useEffect(() => {
        getCategoryList();
        getCourseList();
    }, []);

    return (
        <div className="bg-landing-page">
            <Container maxWidth="xl">
                <Grid container className="homepage-container">
                    <Grid item xs={12}>
                        <h3>Courses in Progress</h3>
                    </Grid>
                    <Grid container item xs={12} className="d-flex jc-flex-start">
                        <Grid item xs={11} md={4} lg={2} className="category-select pr-1-5" >
                            <FormControl variant="filled" fullWidth>
                                <InputLabel id="category-lable">category</InputLabel>
                                <Select
                                    labelId="course-category-label"
                                    id="course-category"
                                    value={categoryValue}
                                    onChange={handleChange}
                                    label="Category"
                                >
                                    <MenuItem value={0} key={0}>
                                        All
                                    </MenuItem>
                                    {category.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.category}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        {progressCourseDetails.length !== 0 ? (
                            <React.Fragment>
                                {progressCourseDetails.map((item, index) => (
                                    <Grid item xs={12} md={4} lg={2} className="mt-1-5 pr-1-5" key={index}>
                                            <Card style={{ cursor:"pointer" }} onClick={() => history.push({
                                                pathname: "/courseDetails",
                                                state: {
                                                    courseInfoId: item.courseId,
                                                },
                                            })}>
                                                <CardMedia
                                                    component="img"
                                                    alt="course images"
                                                    height="140"
                                                    image={item.courseImage}
                                                />
                                                <CardContent>
                                                <Typography gutterBottom variant="h6" component="div" className="card-header">
                                                        {item.courseName}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        {item.courseDescription.slice(0, 85)}...
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                    </Grid>
                                ))}
                                {isLoader ? <Loading /> : <></>}
                            </React.Fragment>
                        ) : (
                            <div className="d-flex jc-center width-100">
                                <p>No courses available in this category.</p>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
