import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Cognisphere from "../../assets/images/Cognisphere.png";
import "./PartnerAdmin.scss";

const adminMenu = [
    {
        adminMenuId: 1,
        adminMenuName: "Partner User Activity",
        adminMenuLink: "/PartnerUserActivity",
    },
    {
        adminMenuId: 2,
        adminMenuName: "User Management",
        adminMenuLink: "/PartnerUserManagement",
    },
];

export default function Admin() {
    return (
        <div className="bg-landing-page">
            <img
                src={Cognisphere}
                alt="Cognisphere"
                className="contact-cognisphere"
            />
            <h3>Administrator</h3>
            <Grid container spacing={2}>
                {adminMenu.map((adminItem) => (
                    <Grid item xs={12} sm={3} key={adminItem.adminMenuId}>
                        <Link to={adminItem.adminMenuLink} key={adminItem.adminMenuId}>
                            <div className="adminMenu-item-container">
                                <div className="adminMenu-item-text-block">
                                    <span>{adminItem.adminMenuName}</span>
                                </div>
                            </div>
                        </Link>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}
