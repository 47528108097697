import React from "react";
import { Link } from "react-router-dom";
import { alpha, makeStyles, ThemeProvider } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  MenuItem,
  Menu,
  Tooltip,
  Zoom,
} from "@material-ui/core";
import SideDrawer from "../../common/SideDrawer/SideDrawer";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import HelpIcon from "@material-ui/icons/HelpOutline";
import SiemensLogoGreen from "../../../assets/images/siemens-logo-petrol.svg";
import { lightTheme } from "../../SharedComponentStyles";
import "../Header/Header.css";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appStyle: {
    backgroundColor: `var(--white)`,
    color: `var(--dark-blue)`,
  },

  title: {
    /*display: 'none',*/
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: `transparent`,
  },
  inputRoot: {
    color: "inherit",
    backgroundColor: "var(--light-sand)",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
    [theme.breakpoints.only("xs")]: {
      width: "6ch",
      "&:focus": {
        width: "6.5ch",
      },
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function Header() {
  const classes = useStyles();

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <div className={classes.grow}>
        <AppBar position="fixed" className={classes.appStyle}>
          <Toolbar className="d-flex jc-space-bt">
            <Link to="/">
              <img
                src={SiemensLogoGreen}
                alt="Siemens"
                className="official-logo"
              />
            </Link>

            {/* <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon className="header-search" />
              </div>
              <InputBase
                placeholder="Search"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div> */}
            {/* <div className={classes.grow} /> */}

            {/*<div className="d-flex align-items-center intra-block">*/}
            {/*  <span className="intra-text">LEAP </span>*/}
            {/*</div>*/}
            <div className="d-flex">
              <Link to="/contact">
                <Tooltip
                  title="Contact Us"
                  TransitionComponent={Zoom}
                  enterDelay={100}
                  leaveDelay={100}
                >
                  <IconButton>
                    <HelpIcon className="icon-dimension" />
                  </IconButton>
                </Tooltip>
              </Link>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
              >
                <SideDrawer />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    </ThemeProvider>
  );
}
