import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
    Container,
    Grid,
    MenuItem,
    FormControl,
    Select,
    Button,
    InputLabel,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import "./Home.scss";
import { baseApiURL } from "../../Utilities/utility";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../SharedComponents/Elements/Loading/Loading";
import SCORMAdapter from "../../SCORM/scormAdapter";
import BannerImage from "../../assets/images/home-banner.png";

export default function Home() {
    const [courseDetails, setCourseDetails] = useState([]);
    const [resumeDetails, setResumeDetails] = useState({});
    const [category, setCategory] = useState([]);
    const [categoryValue, setCategoryValue] = useState(0);
    const [isLoader, setIsLoader] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );

    window.API = new SCORMAdapter();

    const handleChange = (event) => {
        setCategoryValue(event.target.value);
        if (event.target.value == 0) {
            getCourseList();

        } else {
            getCourseListbyId(event.target.value);
        }
    };

    function getCategoryList() {
        //const token = localStorage.getItem('access_token');
        fetch(baseApiURL + "/Master/GetAssignedCategoryList?userId=" + userDetails.id + "&roleId=" + userDetails.roleId, {
            headers: { Authorization: "Bearer " + token },
        })
            .then((response) => response.json())
            .then((data) => {
                setCategory(data.result);
            })
            .catch((error) => console.log("Error:", error));
    }

    function getCourseList() {
        setIsLoader(true);
        fetch(baseApiURL + "/Course/GetAccessCourseList?userId=" + userDetails.id + "&roleId=" + userDetails.roleId, {
            headers: { Authorization: "Bearer " + token },
        })
            .then((response) => response.json())
            .then((data) => {
                setIsLoader(false);
                setCourseDetails(data.result);
            })
            .catch((error) => console.log("Error:", error));
    }

    function getCourseListbyId(id) {
        setIsLoader(true);
        fetch(baseApiURL + "/Course/GetAssignedCourseListByCategory?categoryId=" + id + "&userId=" + userDetails.id + "&roleId=" + userDetails.roleId, {
            headers: { Authorization: "Bearer " + token },
        })
            .then((response) => response.json())
            .then((data) => {
                setIsLoader(false);
                setCourseDetails(data.result);
            })
            .catch((error) => console.log("Error:", error));
    }

    function getResumeList() {
        fetch(baseApiURL + "/Course/GetUserInProgressCourse?id=" + userDetails.id + "&RoleId=" + userDetails.roleId, {
            headers: { Authorization: "Bearer " + token },
        })
            .then((response) => response.json())
            .then((data) => {
                setResumeDetails(data.result);
            })
            .catch((error) => console.log("Error:", error));
    }


    const openCourse = (e) => {
        //var courseUrl = 'http://localhost:48934/assets/courses/SIRIUS%20Modular%20system%20part%201/story.html'
        //var courseUrl = 'http://localhost:48934/assets/courses/SIRIUS%20Modular%20system%20part%201/index_lms.html';
        var courseUrl = baseApiURL + '/assets/courses/' + resumeDetails.courseFolderName + '/index_lms.html';
        //window.open(courseUrl, "newwindow", "height=800, width=1000, toolbar=no, menubar=no, scrollbars=no,location=no,resizable=yes, status=no");
        window.API.loadCourse(courseUrl, resumeDetails.courseId, userDetails.id, resumeDetails.suspendData);
    }

    function CourseInfo(course) {
        debugger;
        window.localStorage.setItem("course", course);
    }

    var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;
    window.history.pushState({ path: newurl }, "", newurl);

    useEffect(() => {
        if (token && userDetails.id) {
            getResumeList();
            getCourseList();
            getCategoryList();
        }
    }, [token, userDetails.id]);

    return (
        <>
            <Grid container item xs={12}><img
                src={BannerImage}
                alt="ApplicationBanner"
                className="home-banner"
            /></Grid>
            <div className="bg-home-page">
                <Container maxWidth="xl">
                    <Grid container className="homepage-container">
                        
                        {resumeDetails ? (
                            <React.Fragment>
                                <Grid container item xs={12} className="resume-block">
                                    <Grid item xs={12} md={3} className="p-1">
                                        <img
                                            src={resumeDetails.courseImage}
                                            alt="current course"
                                            className="resume-watch-image"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={8} className="resume-details-block">
                                        <div className="course-info">
                                            <h2>{resumeDetails.courseName}</h2>
                                            <p>
                                                {resumeDetails.courseDescription}
                                            </p>
                                            <div className="d-flex jc-space-bt resume-action-block mb-1">
                                                <Grid item xs={12} md={4}><span>Last Visited On :-&nbsp;&nbsp;{resumeDetails.lastDateStr} </span></Grid>
                                                <Grid item xs={12} md={4} className="resume-action-btn d-flex jc-flex-end">
                                                    <a href="#">

                                                        <Button
                                                            className="leap-button--primary rseume-course-btn"
                                                            id="ResumeCourseBtn"
                                                            onClick={openCourse}
                                                        >
                                                            Resume Course
                                                    </Button>
                                                    </a>
                                                </Grid>

                                            </div>
                                        </div>
                                    </Grid> </Grid>
                            </React.Fragment>
                        ) : (<React.Fragment></React.Fragment>)}


                        <Grid item xs={12} className="mt-1">
                            <h3>List Of Courses</h3>
                        </Grid>
                        <Grid container item xs={12} className="d-flex jc-flex-start">
                            <Grid item xs={11} md={4} lg={2} className="category-select pr-1-5">
                                <FormControl variant="filled" fullWidth>
                                    <InputLabel id="category-lable">category</InputLabel>
                                    <Select
                                        labelId="course-category-label"
                                        id="course-category"
                                        value={categoryValue}
                                        onChange={handleChange}
                                        label="Category"
                                        className="category-sort"
                                    >
                                        <MenuItem value={0} key={0}>
                                            All
                                        </MenuItem>
                                        {category.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.category}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} className="mb-2">
                            {courseDetails.length !== 0 ? (
                                <React.Fragment>
                                    {courseDetails.map((item, index) => (
                                        <Grid
                                            item
                                            xs={12}
                                            md={4}
                                            lg={2}
                                            className="mt-1-5 pr-1-5"
                                            key={index}
                                        >
                                            <Card style={{ cursor:"pointer" }} onClick={() => history.push({
                                                pathname: "/courseDetails",
                                                state: {
                                                    courseInfoId: item.id,
                                                },
                                            })}>
                                                <CardMedia
                                                    component="img"
                                                    alt="course images"
                                                    height="140"
                                                    image={item.courseImage}
                                                />
                                                <CardContent>
                                                    <Typography
                                                        variant="h6"
                                                        component="div"
                                                        className= "card-header"
                                                    >
                                                        {item.courseName}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        {item.courseDescription.slice(0, 85)}...
                                                        </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))}
                                    {isLoader ? <Loading /> : <></>}
                                </React.Fragment>
                            ) : (
                                <div className="d-flex jc-center width-100">
                                    <p>No courses available in this category.</p>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </Container>
                </div>
        </>
    );
}
