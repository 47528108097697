import React, { isValidElement, useEffect, useState } from "react";
import {
    Grid,
    Select,
    MenuItem,
    FormControl,
    FormHelperText,
    InputLabel,
    fade,
} from "@material-ui/core";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import axios from "axios";
import { baseApiURL } from "../../Utilities/utility";
import { tableHeaderStyle } from "../../SharedComponents/SharedComponentStyles";
import { useDispatch, useSelector } from "react-redux";
import { render } from "react-dom";
import SnackbarCustom from "../../SharedComponents/common/Snackbar/Snackbar";
import Alert from "@material-ui/lab/Alert";
import AutoSelect from "react-select";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => (
        <Clear {...props} ref={ref} className="bg-transparent" />
    )),
    Search: forwardRef((props, ref) => (
        <Search {...props} ref={ref} className="bg-transparent" />
    )),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const api = axios.create({
    baseURL: `https://reqres.in/api`,
});

const userdata = [
    {
        id: 1,
        email: "george.bluth@reqres.in",
        first_name: "George",
        last_name: "Bluth",
        gid: "abcd1234",
        role: "admin",
        status: 1,
    },
    {
        id: 2,
        email: "sam.ruth@reqres.in",
        first_name: "Sam",
        last_name: "Ruth",
        gid: "abcd1432",
        role: "keyuser",
        status: 1,
    },
    {
        id: 3,
        email: "keerthy.suresh@reqres.in",
        first_name: "Keerthy",
        last_name: "Suresh",
        gid: "abcd1543",
        role: "user",
        status: 1,
    },
    {
        id: 4,
        email: "suresh.kaur@reqres.in",
        first_name: "Suresh",
        last_name: "Kaur",
        gid: "bacd1432",
        role: "keyuser",
        status: 0,
    },
    {
        id: 5,
        email: "sai.paul@reqres.in",
        first_name: "Sai",
        last_name: "Paul",
        gid: "abdc1543",
        role: "user",
        status: 0,
    },
];

const categoryList = [
    {
        title: "CI",
        field: "ci",
        value: true,
    },
    {
        title: "LP",
        field: "lp",
        value: false,
    },
    {
        title: "ETC",
        field: "etc",
        value: true,
    },
];

function validateEmail(email) {
    const re =
        /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
    return re.test(String(email).toLowerCase());
}

export default function UserManagement() {
    const token = useSelector((state) => state.saveTokenReducer.token);
    const [userDetails, setUserDetails] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleClose = (e) => {
        setOpen(false);
    };
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const validName = new RegExp(
        '^[-a-zA-Z0-9-()]+(\s+[-a-zA-Z0-9-()]+)*$'
    );
    const validEmail = new RegExp(/^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/);
    const gidExists = false;

  function getDistributorDDLComponent(objProps) {
    //let ddlValue = itemsDistributor.map(d => d.label= objProps.value);
    return (
      <FormControl
        error={!objProps.value ? true : false}
        isValid={!objProps.value ? false : true}
        fullWidth
      >
        <Select
          value={objProps.value}
          defaultValue={objProps.rowData.distributorId}
          onChange={(e) => {
            objProps.onChange(e.target.value);
          }}
        >
          {itemsDistributor.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{!objProps.value ? "Mandatory" : ""}</FormHelperText>
      </FormControl>
    );
  }

  function ValidateDistributor(rowData) {
    return rowData.role === "User" &&
      (rowData.distributorId === undefined ||
        rowData.distributorId === "" ||
        rowData.distributorId === 0)
      ? { isValid: false, helperText: "Mandatory" }
      : true;
  }

    var columns = [
        { title: "id", field: "id", hidden: true },
        {
            title: "First name", field: "firstName",
            cellStyle: {
                minWidth: 200
            },
            validate: (rowData) =>
                rowData.firstName === undefined
                    ? { isValid: false, helperText: 'Mandatory' }
                    : rowData.firstName === ''
                        ? { isValid: false, helperText: 'Mandatory' }
                        : !validName.test(rowData.firstName) ? { isValid: false, helperText: 'No special characters/space allowed', }
                            : rowData.firstName.length > 50 ? { isValid: false, helperText: 'Should be less than 50 characters' } : true

        },
        {
            title: "Last name", field: "lastName",
            cellStyle: {
                minWidth: 200
            },
            validate: (rowData) =>
                rowData.lastName === undefined
                    ? { isValid: false, helperText: 'Mandatory' }
                    : rowData.lastName === ''
                        ? { isValid: false, helperText: 'Mandatory' }
                        : !validName.test(rowData.lastName) ? { isValid: false, helperText: 'No special characters/space allowed' }
                            : rowData.lastName.length > 50 ? { isValid: false, helperText: 'Should be less than 50 characters' } : true
        },
        {
            title: "Email", field: "emailId",
            validate: (rowData) =>
                rowData.emailId === undefined
                    ? { isValid: false, helperText: 'Mandatory' }
                    : rowData.emailId === ''
                        ? { isValid: false, helperText: 'Mandatory' }
                        : !validEmail.test(String(rowData.emailId).toLowerCase()) ? "Invalid Email Format"
                            : rowData.emailId.length > 200 ? { isValid: false, helperText: 'Should be less than 200 characters' }
                                : ((!rowData.id && isEmailExists(rowData.emailId)) || (rowData.id && isEmailExists(rowData.emailId, rowData.id))) ? { isValid: false, helperText: 'Email Id already exists' } : true
        },
        {
            title: "Role",
            field: "role",
            cellStyle: {
                minWidth: 150
            },
            lookup: { User: "Partner User", Admin: "Siemens Admin", Distributor: "Partner Admin" },
            validate: (rowData) =>
                rowData.role === undefined || rowData.role === ''
                    ? { isValid: false, helperText: 'Mandatory' }
                    : true
        },
        {
            title: "GID",
            field: "gid",
            cellStyle: {
                minWidth: 200
            },
            //editable: (rowData) => rowData.role === 'Admin' ? 'always' : 'never',
            validate: (rowData) =>
                rowData.role === 'Admin' && rowData.gid === '' || rowData.role === 'Admin' && rowData.gid === undefined
                    ? { isValid: false, helperText: 'Mandatory' }
                    : rowData.gid !== '' && rowData.role !== 'Admin' && rowData.gid !== null && rowData.gid !== undefined
                        ? {
                            isValid: false, helperText: 'Should Be Blank', disabled: 'true'
                        } : rowData.role === 'Admin' && !validName.test(rowData.gid) ? { isValid: false, helperText: 'No special/space characters allowed' }
                            : rowData.role === 'Admin' && rowData.gid.length != 8 ? { isValid: false, helperText: 'should be of 8 characters' } //: true
                                : rowData.role === 'Admin' && ((!rowData.id && isGIDExists(rowData.gid)) || (rowData.id && isGIDExists(rowData.gid, rowData.id))) ? { isValid: false, helperText: 'GID already exists' } : true
        },
        {
            title: "Partner Admin", field: "distributorId",
            render: (rowData) => <span>{renderDistributorValue(rowData.distributorId)}</span>,
            editComponent: (props) => props.rowData.role === "User" ? getDistributorDDLComponent(props) : "",
            validate: (rowData) => ValidateDistributor(rowData),

        },
        {
            title: "Partner Company", field: "distributorCompanyId",
            cellStyle: {
                minWidth: 300
            },
            render: (rowData) => <span>{renderDistributorCompanyValue(rowData.distributorCompanyId)}</span>,
            //editComponent: (props) => props.rowData.role === "Distributor" ? getDistributorCompanyDDLComponent(props) : "",
            editComponent: (props) => props.rowData.role === "Distributor" ? getCompanyDDLComponent(props) : "",
            validate: (rowData) => ValidateDistributorCompany(rowData),

        },
        {
            title: "Status",
            field: "status",
            lookup: { true: "Active", false: "Inactive" },
            validate: (rowData) =>
                rowData.status === undefined
                    ? { isValid: false, helperText: 'Mandatory' }
                    : rowData.status === ''
                        ? { isValid: false, helperText: 'Mandatory' }
                        : true
        },
    ];
    const [data, setData] = useState([]); //table data
    const [role, setRole] = React.useState("");


    const handleChange = (event) => {
        setRole(event.target.value);
    };

    function getUserList() {
        //const token = localStorage.getItem('access_token');
        fetch(baseApiURL + "/User/GetUserList",
            { headers: { 'Authorization': 'Bearer ' + token } }
        )
            .then(response => response.json())
            .then(data => {
                setUserDetails(data.result);
            })
            .catch(error => console.log("Error:", error));
    }

    //for error handling
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    let [itemsDistributor, setItemsDistributor] = useState([]);
    let [itemsDistributorCompany, setItemsDistributorCompany] = useState([]);

    async function getDistributorDDL() {
        const response = await getDistributor(token);
        setItemsDistributor(
            response.map((item) => ({ value: item.id, label: item.distributor }))
        );
    }

    function getDistributor(token) {
        const options = {
            method: "get",
            headers: { Authorization: "Bearer " + token },
        };
        return fetch(
            baseApiURL + "/User/GetDistributorDDL",
            options
        )
            .then((response) => response.json())
            .then((data) => {
                return data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            });
    }

    async function getDistributorCompanyDDL() {
        const response = await getDistributorCompany(token);
        setItemsDistributorCompany(
            response.map((item) => ({ value: item.id, label: item.companyName }))
        );
    }

    function getDistributorCompany(token) {
        const options = {
            method: "get",
            headers: { Authorization: "Bearer " + token },
        };
        return fetch(
            baseApiURL + "/Master/GetDistributorCompanyList",
            options
        )
            .then((response) => response.json())
            .then((data) => {
                return data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            });
    }

    function getDistributorCompanyDDLComponent(objProps) {
        return (
            <FormControl error={!objProps.value ? true : false} isValid={!objProps.value ? false : true} fullWidth>
                <Select
                    value={objProps.value}
                    defaultValue={objProps.rowData.distributorCompanyId}
                    onChange={(e) => {
                        objProps.onChange(e.target.value);
                    }}
                >
                    {itemsDistributorCompany.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText>{!objProps.value ? "Mandatory" : ""}</FormHelperText>
            </FormControl>
        );
    }

    function ValidateDistributorCompany(rowData) {
        return rowData.role === "Distributor" && (rowData.distributorCompanyId === undefined || rowData.distributorCompanyId === '' || rowData.distributorCompanyId === 0)
            ? { isValid: false, helperText: 'Mandatory' }
            : true
    }

    function getCompanyDDLComponent(objProps) {
        let customStylesDefault = {
            control: (provided, state) => ({
                ...provided,
                border: "none",
                borderBottom: `1px solid #00B3B3`,
                borderRadius: 0,
                cursor: "text",
                boxShadow: "0 0 0 0 transparent !important",
                minHeight: 0,
                ":hover": {
                    border: "none",
                    borderBottom: `1px solid #00B3B3`,
                    borderRadius: 0,
                    boxShadow: "0 0 0 0 transparent !important",
                },
            }),
            singleValue: (provided, state) => ({
                ...provided,
                textAlign: "left",
            }),
            input: (provided, state) => ({
                ...provided,
                textAlign: "left",
                padding: 0,
            }),
            menu: (provided, state) => ({
                ...provided,
                textAlign: "left",
                backgroundColor: `var(--select-active-bg-color)`,
                color: `var(--select-active-text-color)`,
            }),
            menuList: (provided, state) => ({
                ...provided,
                color: `var(--select-active-text-color)`,
            }),
            indicatorSeparator: (provided, state) => ({
                ...provided,
                display: "none",
            }),
            clearIndicator: (provided, state) => ({
                ...provided,
                padding: "1px !important",
                cursor: "pointer",
            }),
            dropdownIndicator: (provided, state) => ({
                ...provided,
                padding: "1px !important",
                cursor: "pointer",
            }),
        };
        let customStyleError = {
            ...customStylesDefault,
            control: (provided, state) => ({
                ...provided,
                border: "none",
                borderBottom: `1px solid var(--red)`,
                borderRadius: 0,
                cursor: "text",
                boxShadow: "0 0 0 0 transparent !important",
                minHeight: 0,
                ":hover": {
                    border: "none",
                    borderBottom: `1px solid var(--red)`,
                    borderRadius: 0,
                    boxShadow: "0 0 0 0 transparent !important",
                },
            }),
        };
        const comp = objProps.rowData.distributorCompanyId
            ? itemsDistributorCompany.find((x) => x.value === objProps.rowData.distributorCompanyId)
            : null;
        return (
            <FormControl error={!objProps.value ? true : false} fullWidth>
                <AutoSelect
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary: `var(--side-drawer-bg-color)`,
                            primary25: `var(--app-permission-list-item-color)`,
                            primary50: `var(--app-permission-list-item-color)`,
                        },
                    })}
                    options={itemsDistributorCompany}
                    value={
                        typeof objProps.value === "string" ||
                            typeof objProps.value === "number"
                            ? comp
                            :
                            objProps.value
                    }
                    onChange={(opt) => {
                        objProps.onChange(opt);
                    }}
                    isClearable
                    styles={customStylesDefault}
                    placeholder="Type Company Name"
                />
                <FormHelperText>
                    {!objProps.value ? "Mandatory" : ""}
                </FormHelperText>
            </FormControl>
        );
    }

  function isGIDExists(gid) {
    const obj = userDetails.find(
      (c) => String(c.gid).toLowerCase() == String(gid).toLowerCase()
    );
    return obj ? true : false;
  }

  function isGIDExists(gid, id) {
    const obj = userDetails.find(
      (c) => String(c.gid).toLowerCase() == String(gid).toLowerCase()
    );

    return obj && obj.id !== id ? true : false;
  }

  function isEmailExists(emailId) {
    const obj = userDetails.find(
      (c) => String(c.emailId).toLowerCase() == String(emailId).toLowerCase()
    );
    return obj ? true : false;
  }

  function isEmailExists(emailId, id) {
    const obj = userDetails.find(
      (c) => String(c.emailId).toLowerCase() == String(emailId).toLowerCase()
    );

    return obj && obj.id !== id ? true : false;
  }

  function renderDistributorValue(value) {
    let obj = {};
    if (itemsDistributor && itemsDistributor.length > 0) {
      obj = itemsDistributor.find((c) => c.value == value);
    }
    if (obj) {
      return obj.label;
    } else {
      return "";
    }
  }

  function renderDistributorCompanyValue(value) {
    let obj = {};
    if (itemsDistributorCompany && itemsDistributorCompany.length > 0) {
      obj = itemsDistributorCompany.find((c) => c.value == value);
    }
    if (obj) {
      return obj.label;
    } else {
      return "";
    }
  }

  function getRole() {
    return (
      <FormControl variant="filled" fullWidth>
        <InputLabel id="category-lable">Role</InputLabel>
        <Select
          labelId="course-category-label"
          id="course-category"
          value={role}
          onChange={handleChange}
          multiple={true}
          label="Role"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>CP</MenuItem>
          <MenuItem value={20}>LP</MenuItem>
          <MenuItem value={30}>Etc.</MenuItem>
        </Select>
      </FormControl>
    );
  }

  const handleRowUpdate = (newData, oldData, resolve) => {
    //validation
    newData.distributorCompanyId = newData.distributorCompanyId.value;
    let errorList = [];
    newData.modifiedBy = userDetails.Id;
    if (newData.firstName === "") {
      errorList.push("Please enter first name");
    }
    if (newData.lastName === "") {
      errorList.push("Please enter last name");
    }
    if (newData.emailId === "" || validateEmail(newData.emailId) === false) {
      errorList.push("Please enter a valid email");
    }
    if (errorList.length === 0) {
      const options = {
        method: "put",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(newData),
      };
      return fetch(baseApiURL + "/User/UserEdit?newData=" + newData, options)
        .then((response) => response.json())
        .then((data) => {
          if (data.result.response) {
            handleSnackOpen("User Updated Successfully", "success");
            resolve();
            setIsLoader(false);
            getUserList();
          } else {
            handleSnackOpen("Error in updating User", "error");
            //reject();
            setIsLoader(false);
          }
        })
        .catch((error) => {
          // handleSnackOpen("Exception : " + error, "error");
          //reject();
          setIsLoader(false);
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  };

    const handleRowAdd = (newData, resolve) => {
        //validation
        //newData.distributorCompanyId = newData.distributorCompanyId.value;
        if (newData.role === "Distributor") { newData.distributorCompanyId = newData.distributorCompanyId.value; }
        let errorList = [];
        if (newData.firstName === undefined) {
            errorList.push("Please enter first name");
        }
        if (newData.lastName === undefined) {
            errorList.push("Please enter last name");
        }
        if (newData.emailId === undefined || validateEmail(newData.emailId) === false) {
            errorList.push("Please enter a valid email");
        }
        if (errorList.length < 1) {
            const options = {
                method: "post",
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify(newData),
            };
            return fetch(
                baseApiURL + "/User/AddUserDetails?newData=" + newData,
                options
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data.result.response) {
                        handleSnackOpen("User Added Successfully", "success");
                        resolve();
                        setIsLoader(false);
                        getUserList();
                    } else {
                        handleSnackOpen("Error in adding User", "error");
                        //reject();
                        setIsLoader(false);
                    }
                })
                .catch((error) => {
                    // handleSnackOpen("Exception : " + error, "error");
                    //reject();
                    setIsLoader(false);
                });
        } else {
            setErrorMessages(errorList);
            setIserror(true);
            resolve();
        }
    };

  const handleRowDelete = (oldData, resolve) => {
    const options = {
      method: "put",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(oldData),
    };
    return fetch(baseApiURL + "/User/UserDelete?oldData=" + oldData, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.result.response) {
          handleSnackOpen("User InActivated Successfully", "success");
          resolve();
          setIsLoader(false);
          getUserList();
        } else {
          handleSnackOpen("Error in inactivating User", "error");
          //reject();
          setIsLoader(false);
        }
      })
      .catch((error) => {
        console.log("Error : ", error);
      });
  };

  useEffect(() => {
    if (token) {
      getUserList();
      getDistributorDDL();
      getDistributorCompanyDDL();
    }
  }, [token]);

  return (
    <div className="bg-landing-page">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {
            <div>
              {iserror && (
                <Alert severity="error">
                  {errorMessages.map((msg, i) => {
                    return <div key={i}>{msg}</div>;
                  })}
                </Alert>
              )}
            </div>
          }
          <Grid item xs={12}>
            <h3>User Management</h3>
          </Grid>
          <MaterialTable
            title="User List"
            columns={columns}
            data={userDetails}
            icons={tableIcons}
            options={{
              headerStyle: tableHeaderStyle,
              draggable: false,
            }}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  handleRowUpdate(newData, oldData, resolve);
                }),
              onRowAdd: (newData) =>
                  new Promise((resolve) => {
                  handleRowAdd(newData, resolve);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  handleRowDelete(oldData, resolve);
                }),
            }}
          />
        </Grid>
        <SnackbarCustom
          open={open}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleClose}
        />
      </Grid>
    </div>
  );
}
