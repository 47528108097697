import React, { useEffect, useState, useRef } from "react";
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, FormControl, Select, InputLabel } from "@material-ui/core";
import { forwardRef } from "react";
import MaterialTable, { MTableActions } from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import axios from "axios";
import { tableHeaderStyle } from "../../SharedComponents/SharedComponentStyles";
import { baseApiURL } from "../../Utilities/utility";
import { useDispatch, useSelector } from "react-redux"
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import SnackbarCustom from "../../SharedComponents/common/Snackbar/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";



//import Alert from "@material-ui/lab/Alert";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => (
        <Clear {...props} ref={ref} className="bg-transparent" />
    )),
    Search: forwardRef((props, ref) => (
        <Search {...props} ref={ref} className="bg-transparent" />
    )),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};


export default function CourseManagement() {

    const tableRef = useRef();
    const [courseDetails, setCourseDetails] = useState([]);
    const [data, setData] = useState([]); //table data
    const [open, setOpen] = useState(false);
    const [category, setCategory] = useState([]);
    const token = useSelector((state) => state.saveTokenReducer.token);
    const [isLoader, setIsLoader] = useState(false);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const [distributorList, setDistributorList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [courseList, setCourseList] = useState([]);
    const [categoryValue, setCategoryValue] = useState(0);
    const [selectedCourseList, setSelectedCourseList] = useState([]);
    const [selectedCourseId, setSelectedCourseId] = useState([]);
    const [editUser, setEditUser] = useState(0);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackClose = (e) => {
        setSnackOpen(false);
    };
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setSnackOpen(true);
    };
    const [courseManagementList, setCourseManagementList] = useState([]);
    const [selectedCheckbox, setSelectedCheckbox] = useState(-1);
    const courseInfo = useState();
    const [selectAll, setSelectAll] = useState(false);


    var columns = [
        { title: "id", field: "id", hidden: true },
        { title: "First name", field: "firstName" },
        { title: "Last name", field: "lastName" },
        { title: "Email", field: "emailId" },
        {
            title: "Assign Course", field: "", render: (row) => <div><Button
                className="leap-button--primary rseume-course-btn"
                id="DownloadDataBtn"
                onClick={() => handleAddCourse(row.id)}

            >
                Courses
            </Button></div>
        },
    ];

    var innerColumns = [
        { title: "Course Name", field: "courseName" },
        { title: "Category Name", field: "courseCategory" },
        //{
        //    title: "Access",
        //    field: "isSelected",
        //    editComponent: (props) => {
        //        return (
        //            <Checkbox
        //                checked={props.isSelected}
        //                onChange={(e) => props.onChange(e.target.checked)}
        //            />
        //        );
        //    },
        //    render: (rowdata) => (
        //        <input type="checkbox" checked={rowdata.isSelected} />
        //    )
        //}
    ];




    const handleAddCourse = (userId) => {
        setEditUser(userId);
        getCourseManagement(userId);
        setOpen(true);
    }

    function getUserCourseAccessList(userId) {
        const options = {
            method: "get",
            headers: { Authorization: "Bearer " + token }
        };
        return fetch(
            baseApiURL + "/Course/GetCourseAccessList?userId=" + userId,
            options
        )
            .then((response) => response.json())
            .then((data) => {
                var categoryVar = data.result.map(function (categoryId) { return categoryId.categoryId; });
                var courseVar = data.result.map(function (id) { return id.courseId; });
                if (categoryVar) {
                    setSelectedCategory(categoryVar);
                    getCourseListbyId(categoryVar);
                    setSelectedCourseId(courseVar);
                    getCourseListbyCourseId(courseVar)
                }
                setSelectedCategory(categoryVar);
            })
            .catch((error) => {
                console.log("Error : ", error);
            });
    }

    function handleClose() {
        setOpen(false);
    }
    const handleChange = (event) => {
        setSelectedCategory(event.target.value);
        if (event.target.value != 0) {
            getCourseListbyId(event.target.value);
        }
        else {
            setCourseList([]);
        }
    };

    function handleSubmitCourse() {
        if (selectedCategory.length === 0) {
            handleSnackOpen("Please select category", "Error");
        }
        else if (selectedCourseList.length === 0) {
            handleSnackOpen("Please select course", "Error");
        }
        else {
            setOpen(false);
            var newData = selectedCourseList.map(function (param) { return { "courseId": param.id, "categoryId": param.courseCategory, "userId": editUser, "createdBy": userDetails.id } });
            newData = JSON.stringify(newData);
            const options = {
                method: "post",
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                }
            };
            return fetch(
                baseApiURL + "/Course/AddUserCourseAccessDetails?newData=" + newData + "&userId=" + editUser,
                options
            )
                .then((response) => response.json())
                .then((data) => {
                    handleSnackOpen("User course access details updated successfully", "success");
                })
                .catch((error) => {
                    setIsLoader(false);
                });
        }
    }

    function getCategoryList() {
        //const token = localStorage.getItem('access_token');
        fetch(baseApiURL + "/Master/GetCategoryList", {
            headers: { Authorization: "Bearer " + token },
        })
            .then((response) => response.json())
            .then((data) => {
                setCategory(data.result);
            })
            .catch((error) => console.log("Error:", error));
    }
    function getCourseListbyId(id) {
        setIsLoader(true);
        fetch(baseApiURL + "/Course/GetCourseListByCategoryList?categoryId=" + id, {
            headers: { Authorization: "Bearer " + token },
        })
            .then((response) => response.json())
            .then((data) => {
                setIsLoader(false);
                setCourseList(data.result);
            })
            .catch((error) => console.log("Error:", error));
    }
    function getCourseListbyCourseId(id) {
        setIsLoader(true);
        fetch(baseApiURL + "/Course/GetCourseListByCourseIdList?courseId=" + id, {
            headers: { Authorization: "Bearer " + token },
        })
            .then((response) => response.json())
            .then((data) => {
                setIsLoader(false);
                setSelectedCourseList(data.result);
            })
            .catch((error) => console.log("Error:", error));
    }

    function getCourseManagement(userId) {
        setIsLoader(true);
        fetch(baseApiURL + "/Course/GetCourseManagementList?userId=" + userId, {
            headers: { Authorization: "Bearer " + token },
        })
            .then((response) => response.json())
            .then((data) => {
                setIsLoader(false);
                setCourseManagementList(data.result);
                var chk = (data.result.filter(function (user) {
                    return user.isSelected === true
                }));
                setSelectedCheckbox(chk);
                getSelectAllValue(data.result);
            })
            .catch((error) => console.log("Error:", error));
    }

    function getDistributor(token) {
        const options = {
            method: "get",
            headers: { Authorization: "Bearer " + token },
        };
        return fetch(
            baseApiURL + "/User/GetDistributorDDL",
            options
        )
            .then((response) => response.json())
            .then((data) => {
                setDistributorList(data.result);
            })
            .catch((error) => {
                console.log("Error : ", error);
            });
    }

    const handleSave = (e) => {
        let selectedList = courseManagementList.filter(x => x.isSelected === true)?.map(c => { return { "courseId": c.id, "categoryId": c.categoryId, "userId": editUser, "createdBy": userDetails.id } });
        if (selectedList.length <= 0) {
            alert("1 Course must be selected");
        }
        else {
            let newData = JSON.stringify(selectedList);
            const options = {
                method: "post",
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                }
            };
            //return fetch(
            //    baseApiURL + "/Course/AddUserCourseAccessDetails?newData=" + newData + "&userId=" + editUser,
            //    options
            //)
            //    .then((response) => response.json())
            //    .then((data) => {
            //        getCourseManagement(editUser);
            //        handleSnackOpen("User course access details updated successfully", "success");
            //    })
            //    .catch((error) => {
            //        setIsLoader(false);
            //    });
        }
    }
    const actions = [
        {
            icon: () => GetAcceptButton(),
            //tooltip: 'Add Row',
            //isFreeAction: true,
            onClick: (event, rowData) => handleAccept(rowData),
            //onClick: (event) => handleSave(event),
            hidden: false,
        },
    ];

    function onSelectChange(e, rowData) {
        // alert("handle change");
        //debugger;

        if (rowData !== undefined) {
            rowData.isSelected = rowData.isSelected ? false : true;
            setCourseManagementList(
                courseManagementList.map((item) => {
                    return item.id === rowData.id ? rowData : item;
                })
            );

        }
        else {
            if (e.length == 0) {
                if (searchedData.length > 0) {
                    //uncheckSearched
                    for (var i = 0; i < courseManagementList.length; i++) {
                        for (var j = 0; j < searchedData.length; j++) {
                            searchedData[j].isSelected = false;
                            setCourseManagementList(
                                courseManagementList.map((item) => {
                                    return item.id === searchedData[j].id ? searchedData[j] : item;
                                })
                            );
                        }
                    }
                }
                else {
                    //uncheckAll
                    for (var i = 0; i < courseManagementList.length; i++) {
                        courseManagementList[i].isSelected = false;
                    }
                }
            }
            else if (e.length == courseManagementList.length) {
                if (searchedData.length > 0) {
                    //checkSearched
                    for (var i = 0; i < courseManagementList.length; i++) {
                        for (var j = 0; j < searchedData.length; j++) {
                            searchedData[j].isSelected = true;
                            setCourseManagementList(
                                courseManagementList.map((item) => {
                                    return item.id === searchedData[j].id ? searchedData[j] : item;
                                })
                            );
                        }
                    }
                }
                else {
                    //checkAll
                    for (var i = 0; i < courseManagementList.length; i++) {
                        courseManagementList[i].isSelected = true;
                    }
                }
                //setSelectAll(true);
                //for (var i = 0; i < courseManagementList.length; i++) {
                //    courseManagementList[i].isSelected = true;
                //}
                //setCourseManagementList(courseManagementList.map((item) => {
                //    return item;
                //}));




            }
            //else if (e.length < courseManagementList.length && e.length != 0) {
            //    //partialcheck;
            //    setSelectAll(true);
            //    for (var i = 0; i < courseManagementList.length; i++) {
            //        for (var j = 0; j < e.length; j++) {
            //            e[j].isSelected = true;
            //            setCourseManagementList(
            //                courseManagementList.map((item) => {
            //                    return item.id === e[j].id ? e[j] : item;
            //                })
            //            );
            //        }
            //    }
            //    //setCourseManagementList(courseManagementList.map((item) => {
            //      //return item;
            //   //}));
            //    //GetAcceptButton();


            //}
        }
    }
    function handleAccept(rowData) {
        //alert("handle accept");
        var lst = courseManagementList.filter(row => row.isSelected == true);
        var newData = lst.map(function (param) { return { "courseId": param.id, "categoryId": param.categoryId, "userId": editUser, "createdBy": userDetails.id } });
        newData = JSON.stringify(newData);
        const options = {
            method: "post",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            }
        };
        return fetch(
            baseApiURL + "/Course/AddUserCourseAccessDetails?newData=" + newData + "&userId=" + editUser,
            options
        )
            .then((response) => response.json())
            .then((data) => {
                getCourseManagement(editUser);
                handleSnackOpen("User course access details updated successfully", "success");
            })
            .catch((error) => {
                setIsLoader(false);
            });
    }

    function GetAcceptButton() {
        return (
            <Button variant="contained" className="leap-button--primary rseume-course-btn" onClick={(event, rowData) => handleAccept(rowData)} title="Save" >
                Save
            </Button >
        );
    }
    let flag = 0;
    let [selectedData, setselectedData] = useState([]);
    let [unselectedData, setunselectedData] = useState([]);
    let [searchedData, setsearchedData] = useState([]);
    function getSelectAllValue(data) {
        setsearchedData(data);
        const dataSelected = data.filter(x => x.isSelected === true);
        const dataUnSelected = data.filter(x => x.isSelected === false);
        if (data?.length === dataSelected?.length) {
            //setSelectAll(true);
            //flag = 0;
            setselectedData(dataSelected);

        }
        if (data?.length === dataUnSelected?.length) {
            //setSelectAll(true);
            //flag = 0;
            setunselectedData(dataUnSelected);

        }

    }

    useEffect(() => {
        if (token) {
            getDistributor(token);
        }
    }, [token]);

    return (
        <div className="bg-landing-page">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <h3>Course Management</h3>
                </Grid>
                <Grid item xs={12}>
                    <MaterialTable
                        title=""
                        columns={columns}
                        data={distributorList}
                        icons={tableIcons}
                        options={{
                            headerStyle: tableHeaderStyle,
                            draggable: false,
                        }}

                    />
                </Grid>
            </Grid>
            <Dialog
                disableEscapeKeyDown
                disableBackdropClick
                maxWidth="md"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="width-100"
            >
                <DialogTitle id="alert-dialog-title">Course Access Management</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {/* <Grid item xs={12} md={4} lg={4}>
                            <FormControl variant="filled" fullWidth>
                                 <InputLabel id="category-lable">Select Category</InputLabel>
                                <Select
                                    multiple
                                    labelId="course-category-label"
                                    id="course-category"
                                    value={selectedCategory}
                                    fullWidth
                                    onChange={handleChange}
                                    label="Category"
                                >
                                    {/*<MenuItem value={0} key={0}>*/}
                        {/*    All*/}
                        {/*</MenuItem>*/}
                        {/* {category.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.category}
                                        </MenuItem>
                                    ))} 
                                </Select> 
                            </FormControl>
                        </Grid>*/}
                        <Grid item xs={12}>
                            <MaterialTable
                                title=""
                                tableRef={tableRef}
                                columns={innerColumns}
                                data={courseManagementList}
                                icons={tableIcons}
                                //actions={actions}
                                components={{
                                    Actions: (props) => (
                                        <>
                                            <MTableActions {...props} />
                                            {GetAcceptButton()}
                                        </>
                                    )
                                }}
                                onSearchChange={() => { getSelectAllValue(tableRef.current.dataManager.searchedData); }}
                                onSelectionChange={(event, rowData) => onSelectChange(event, rowData)}
                                /*actions={[
                                    {
                                        icon: AddBox,
                                        tooltip: 'Add Row',
                                        isFreeAction: true,
                                        onClick: () => {
                                            handleAccept()
                                        }
                                    }
                                ]}*/
                                options={{
                                    draggable: true,
                                    //actionsColumnIndex: selectedCheckbox,
                                    selection: true,
                                    selectionProps: (rowData) => ({
                                        checked: rowData.isSelected,


                                    }),

                                    showTextRowsSelected: false,
                                }}

                            />

                        </Grid>
                        {/* <Grid item xs={12} md={8} lg={8}>
                            <Autocomplete
                                fullWidth
                                multiple
                                id="checkboxes-tags-demo"
                                options={courseList}
                                value={selectedCourseList}
                                onChange={handleCourseChange}
                                hideSelectedOptions={true}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.courseName}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.courseName ? option.courseName : ""}
                                    </React.Fragment>
                                )}
                                style={{ width: 500 }}
                                renderInput={(params) => (
                                    <TextField {...params} variant="filled" label="Select Course" />
                                )}
                            />
                        </Grid>*/}
                    </Grid>
                </DialogContent>
                <DialogActions className="error-popup mng-button">
                    <Grid item xs={12} lg={3}>
                        <Button className="leap-button--secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Grid>
                    {/*<Grid item xs={12} lg={5} className="d-flex jc-center">*/}
                    {/*<Button className="leap-button--primary" onClick={handleSubmitCourse}>*/}
                    {/* Submit Course List*/}
                    {/* </Button>*/}
                    {/*</Grid>*/}
                </DialogActions>
            </Dialog>

            <SnackbarCustom
                open={snackOpen}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleSnackClose}
            />
        </div>
    );
}
