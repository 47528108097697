import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import HomePage from "../Pages/Home/Home";
import History from "../Pages/History/History";
import Progress from "../Pages/Progress/Progress";
import UserManagement from "../Pages/Admin/UserManagement";
import Download from "../Pages/Download/Download";
import KeyUser from "../Pages/Distributor/KeyUser";
import UserActivity from "../Pages/Admin/UserActivity";
import Admin from "../Pages/Admin/Admin";
import Contact from "../Pages/ContactUs/Contact";
import PartnerAdmin from "../Pages/Distributor/PartnerAdmin";
import PartnerUserActivity from "../Pages/Distributor/PartnerUserActivity";
import CourseDetails from "../Pages/CourseDetails/CourseDetails";
import CourseManagement from "../Pages/Admin/CourseManagement";
import UnauthorizedAccess from "../Pages/UnauthorizedAccess";


export const MasterRoute = (
    <Switch>
        <Redirect from="/?Code*" to="/" />
        <Route path="/" exact render={() => <HomePage />} />
        <Route path="/history" exact render={() => <History />} />
        <Route path="/progress" exact render={() => <Progress />} />
        <Route path="/userManagement" exact render={() => <UserManagement />} />
        <Route path="/download" exact render={() => <Download />} />
        <Route path="/userActivity" exact render={() => <UserActivity />} />
        <Route path="/admin" exact render={() => <Admin />} />
        <Route path="/contact" exact render={() => <Contact />} />
        <Route path="/courseDetails" exact render={() => <CourseDetails />} />
        <Route path="/courseManagement" exact render={() => <CourseManagement />} />
        <Route render={() => <UnauthorizedAccess />} />
    </Switch>
);

export const PartnerRoute = (
    <Switch>
        <Redirect from="/?Code*" to="/" />
        <Route path="/" exact render={() => <HomePage />} />
        <Route path="/history" exact render={() => <History />} />
        <Route path="/progress" exact render={() => <Progress />} />]
        <Route path="/download" exact render={() => <Download />} />
        <Route path="/PartnerUserManagement" exact render={() => <KeyUser />} />
        <Route path="/contact" exact render={() => <Contact />} />
        <Route path="/partnerAdmin" exact render={() => <PartnerAdmin />} />
        <Route path="/partnerUserActivity" exact render={() => <PartnerUserActivity />} />
        <Route path="/courseDetails" exact render={() => <CourseDetails />} />
        <Route render={() => <UnauthorizedAccess />} />
    </Switch>
);

export const UserRoute = (
    <Switch>
        <Redirect from="/?Code*" to="/" />
        <Route path="/" exact render={() => <HomePage />} />
        <Route path="/history" exact render={() => <History />} />
        <Route path="/progress" exact render={() => <Progress />} />
        <Route path="/download" exact render={() => <Download />} />
        <Route path="/contact" exact render={() => <Contact />} />
        <Route path="/courseDetails" exact render={() => <CourseDetails />} />
        <Route render={() => <UnauthorizedAccess />} />
    </Switch>
);
