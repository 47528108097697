import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import UnauthorizedAccess from "../Pages/UnauthorizedAccess";


export const UnAuthRoute = (
    <Switch>
        <Redirect from="/?Code*" to="/" />
        <Route path="/" component={UnauthorizedAccess} />
    </Switch>
);