var errorCode;
const CMIString256 = '^.{0,255}$';
const CMIString4096 = '^.{0,4096}$';
const CourseState = '2Xp~2K160708090a0b0c0d0e0f0g0h0i0j0k0l0m0n0o0p0q0r0s0t0u0v0w0x0y0z0A0B0C0D0E0F0G0H0I0J0K0L0M0N0O0P0Q0R0S0T0U0V0W0X0Y0~2y4~2u41001513~2j40101201112012120131201412015120161201712018120191201a1201b1201c1201d1201e1201f1201g1201h1201i1201j1201k1201l1201m1201n1201o1201p1201q1201r1201s1201t1201u1201v1201w1201x1201y1201z1201A1201B1201C1201D1201E1201F1201G1201H1201I1201J1201K1201L1201M12010130111301213013130141301513~251kGGGGGGGGGGGGGGGG20i01^1^1^1^1^10v_player.6Nsa7H1tGb6.5u0dPpGVTpR1^1^000~2fi~2M3f210b101001a1a103sZ0~2t234003400x79000o000f0141^h_default_Disabledx79000o0j2i0141^h_default_Disabledx79000o0y1h0141^h_default_DisabledG79050o0N0g01c1^q_default_Selected_Disabled3400T0R2LoL34003400340034003400q70020181^g_default_Visited00000~2G3f210b101001a1a113P01~2n234003400r78000141^h_default_Disabledx79000o0N0g0141^h_default_DisabledG79050o000f01c1^q_default_Selected_Disabledx79000o0y1h0141^h_default_Disabled3400T0R2EiL34003400340034003400q70020181^g_default_Visited00000~2M3f210b101021010133BI0~2t234003400G79030o0y1h01c1^q_default_Selected_Disabledx79000o000f0141^h_default_Disabledx79000o0j2i0141^h_default_Disabledx79000o0N0g0141^h_default_Disabled3400TR2KkL34003400340034003400q70020181^g_default_Visited000000~2p3h41110b1010210101138e1~2423400340066c0014mSd0tn0941k001871C841_$mSd0vn0943q001271C843_$mSd0wn0940h001971C840_$mSd0un0942n001771C842_$66c001666c001366c00153400TR28kL34003400340034003400q70020181^g_default_Visited000000~2n3h41110b1010210101110~2423400340066c0015mSd0An0940h001971C840_$mSd0xn0941k001871C841_$mSd0zn0943q001271C843_$mSd0yn0942n001771C842_$66c001666c001466c00133400TR2BoL34003400340034003400q70020181^g_default_Visited00000002110';
const CMITime = '^([0-2]{1}[0-9]{1}):([0-5]{1}[0-9]{1}):([0-5]{1}[0-9]{1})(\.[0-9]{1,2})?$';
const CMITimespan = '^([0-9]{2,4}):([0-9]{2}):([0-9]{2})(\.[0-9]{1,2})?$';
const CMIInteger = '^\\d+$';
const CMISInteger = '^-?([0-9]+)$';
const CMIDecimal = '^-?([0-9]{0,3})(\.[0-9]{1,2})?$';
const CMIIdentifier = '^\\w{1,255}$';
const CMIFeedback = CMIString256; // This must be redefined
const CMIIndex = '[._](\\d+).';
// Vocabulary Data Type Definition
const CMIStatus = '^passed$|^completed$|^failed$|^incomplete$|^browsed$';
const CMIStatus2 = '^passed$|^completed$|^failed$|^incomplete$|^browsed$|^not attempted$';
const CMIExit = '^time-out$|^suspend$|^logout$|^$';
const CMIType = '^true-false$|^choice$|^fill-in$|^matching$|^performance$|^sequencing$|^likert$|^numeric$';
const CMIResult = '^correct$|^wrong$|^unanticipated$|^neutral$|^([0-9]{0,3})?(\.[0-9]{1,2})?$';
const NAVEvent = '^previous$|^continue$';
const CMIEntry = '^ab-initio$|^resume$';
// Children lists
const cmi_children = 'core, suspend_data, launch_data, comments, objectives, student_data, student_preference, interactions';
const core_children = 'student_id, student_name, lesson_location, credit, lesson_status, entry, score, total_time, lesson_mode, exit, session_time';
const score_children = 'raw, min, max';
const objectives_children = 'id, score, status';
const student_data_children = 'mastery_score, max_time_allowed, time_limit_action';
const student_preference_children = 'audio, language, speed, text';
const interactions_children = 'id, objectives, time, type, correct_responses, weighting, student_response, result, latency';
// Data ranges
const score_range = '0#100';
const audio_range = '-1#100';
const speed_range = '-100#100';
const weighting_range = '-100#100';
const text_range = '-1#1';

//defaultvalue
const coreEnty = "";
const Scoreraw = 0;
const Scoremax = 100;
const Scoremin = 0;

// The SCORM 1.2 data model
var datamodel = {
    'cmi._children': { 'defaultvalue': cmi_children, 'mod': 'r', 'writeerror': '402' },
    'cmi._version': { 'defaultvalue': '3.4', 'mod': 'r', 'writeerror': '402' },
    'cmi.core._children': { 'defaultvalue': core_children, 'mod': 'r', 'writeerror': '402' },
    //'cmi.core.student_id': { 'defaultvalue': 'chandler', 'mod': 'r', 'writeerror': '403' },
    //'cmi.core.student_name': { 'defaultvalue': 'Li, Chandler', 'mod': 'r', 'writeerror': '403' },
    'cmi.core.lesson_location': { 'defaultvalue': '', 'format': CMIString256, 'mod': 'rw', 'writeerror': '405' },
    'cmi.core.credit': { 'defaultvalue': 'credit', 'mod': 'r', 'writeerror': '403' },
    'cmi.core.lesson_status': { 'defaultvalue': 'incomplete', 'format': CMIStatus, 'mod': 'rw', 'writeerror': '405' },
    'cmi.core.entry': { 'defaultvalue': coreEnty, 'format': CMIEntry, 'mod': 'r', 'writeerror': '403' },  ///////////
    'cmi.core.score._children': { 'defaultvalue': score_children, 'mod': 'r', 'writeerror': '402' },
    'cmi.core.score.raw': { 'defaultvalue': Scoreraw, 'format': CMIDecimal, 'range': score_range, 'mod': 'rw', 'writeerror': '405' },
    'cmi.core.score.max': { 'defaultvalue': Scoremax, 'format': CMIDecimal, 'range': score_range, 'mod': 'rw', 'writeerror': '405' },
    'cmi.core.score.min': { 'defaultvalue': Scoremin, 'format': CMIDecimal, 'range': score_range, 'mod': 'rw', 'writeerror': '405' },
    'cmi.core.total_time': { 'defaultvalue': '', 'mod': 'r', 'writeerror': '403' },
    'cmi.core.lesson_mode': { 'defaultvalue': 'normal', 'mod': 'r', 'writeerror': '403' },
    'cmi.core.exit': { 'defaultvalue': 'suspend', 'format': CMIExit, 'mod': 'w', 'readerror': '404', 'writeerror': '405' },
    'cmi.core.session_time': { 'format': CMITimespan, 'mod': 'w', 'defaultvalue': '00:00:00', 'readerror': '404', 'writeerror': '405' },
    //'cmi.suspend_data': { 'defaultvalue': 'viewed=1,2|lastviewedslide=1|s1=10110,s2=00010,s3=00010,s4=00010,s5=00000,s6=00000,s7=00000,s8=00000,s9=00010,s10=00000,s11=00000,s12=00000,s13=00000,s14=00000,s15=00000,s16=00000,s17=00010,s18=00010,LCS=0,', 'format': CMIString4096, 'mod': 'rw', 'writeerror': '405' },
    'cmi.suspend_data': { 'defaultvalue': CourseState, 'format': CMIString4096, 'mod': 'rw', 'writeerror': '405' },
    'cmi.launch_data': { 'defaultvalue': '', 'mod': 'r', 'writeerror': '403' },
    'cmi.comments': { 'defaultvalue': '', 'format': CMIString4096, 'mod': 'rw', 'writeerror': '405' },
    'cmi.comments_from_lms': { 'mod': 'r', 'writeerror': '403' },
    'cmi.objectives._children': { 'defaultvalue': objectives_children, 'mod': 'r', 'writeerror': '402' },
    'cmi.objectives._count': { 'mod': 'r', 'defaultvalue': '0', 'writeerror': '402' },
    'cmi.objectives.n.id': { 'pattern': CMIIndex, 'format': CMIIdentifier, 'mod': 'rw', 'writeerror': '405' },
    'cmi.objectives.n.score._children': { 'pattern': CMIIndex, 'mod': 'r', 'writeerror': '402' },
    'cmi.objectives.n.score.raw': { 'defaultvalue': '', 'pattern': CMIIndex, 'format': CMIDecimal, 'range': score_range, 'mod': 'rw', 'writeerror': '405' },
    'cmi.objectives.n.score.min': { 'defaultvalue': '', 'pattern': CMIIndex, 'format': CMIDecimal, 'range': score_range, 'mod': 'rw', 'writeerror': '405' },
    'cmi.objectives.n.score.max': { 'defaultvalue': '', 'pattern': CMIIndex, 'format': CMIDecimal, 'range': score_range, 'mod': 'rw', 'writeerror': '405' },
    'cmi.objectives.n.status': { 'pattern': CMIIndex, 'format': CMIStatus2, 'mod': 'rw', 'writeerror': '405' },
    'cmi.student_data._children': { 'defaultvalue': student_data_children, 'mod': 'r', 'writeerror': '402' },
    'cmi.student_data.mastery_score': { 'defaultvalue': '', 'mod': 'r', 'writeerror': '403' },
    'cmi.student_data.max_time_allowed': { 'defaultvalue': '', 'mod': 'r', 'writeerror': '403' },
    'cmi.student_data.time_limit_action': { 'defaultvalue': '', 'mod': 'r', 'writeerror': '403' },
    'cmi.student_preference._children': { 'defaultvalue': student_preference_children, 'mod': 'r', 'writeerror': '402' },
    'cmi.student_preference.audio': { 'defaultvalue': '0', 'format': CMISInteger, 'range': audio_range, 'mod': 'rw', 'writeerror': '405' },
    'cmi.student_preference.language': { 'defaultvalue': '', 'format': CMIString256, 'mod': 'rw', 'writeerror': '405' },
    'cmi.student_preference.speed': { 'defaultvalue': '0', 'format': CMISInteger, 'range': speed_range, 'mod': 'rw', 'writeerror': '405' },
    'cmi.student_preference.text': { 'defaultvalue': '0', 'format': CMISInteger, 'range': text_range, 'mod': 'rw', 'writeerror': '405' },
    'cmi.interactions._children': { 'defaultvalue': interactions_children, 'mod': 'r', 'writeerror': '402' },
    'cmi.interactions._count': { 'mod': 'r', 'defaultvalue': '0', 'writeerror': '402' },
    'cmi.interactions.n.id': { 'pattern': CMIIndex, 'format': CMIIdentifier, 'mod': 'w', 'readerror': '404', 'writeerror': '405' },
    'cmi.interactions.n.objectives._count': { 'pattern': CMIIndex, 'mod': 'r', 'defaultvalue': '0', 'writeerror': '402' },
    'cmi.interactions.n.objectives.n.id': { 'pattern': CMIIndex, 'format': CMIIdentifier, 'mod': 'w', 'readerror': '404', 'writeerror': '405' },
    'cmi.interactions.n.time': { 'pattern': CMIIndex, 'format': CMITime, 'mod': 'w', 'readerror': '404', 'writeerror': '405' },
    'cmi.interactions.n.type': { 'pattern': CMIIndex, 'format': CMIType, 'mod': 'w', 'readerror': '404', 'writeerror': '405' },
    'cmi.interactions.n.correct_responses._count': { 'pattern': CMIIndex, 'mod': 'r', 'defaultvalue': '0', 'writeerror': '402' },
    'cmi.interactions.n.correct_responses.n.pattern': { 'pattern': CMIIndex, 'format': CMIFeedback, 'mod': 'w', 'readerror': '404', 'writeerror': '405' },
    'cmi.interactions.n.weighting': { 'pattern': CMIIndex, 'format': CMIDecimal, 'range': weighting_range, 'mod': 'w', 'readerror': '404', 'writeerror': '405' },
    'cmi.interactions.n.student_response': { 'pattern': CMIIndex, 'format': CMIFeedback, 'mod': 'w', 'readerror': '404', 'writeerror': '405' },
    'cmi.interactions.n.result': { 'pattern': CMIIndex, 'format': CMIResult, 'mod': 'w', 'readerror': '404', 'writeerror': '405' },
    'cmi.interactions.n.latency': { 'pattern': CMIIndex, 'format': CMITimespan, 'mod': 'w', 'readerror': '404', 'writeerror': '405' },
    'nav.event': { 'defaultvalue': '', 'format': NAVEvent, 'mod': 'w', 'readerror': '404', 'writeerror': '405' }
};
//
// Datamodel inizialization
//
var cmi = new Object();
cmi.core = new Object();
cmi.core.score = new Object();
cmi.objectives = new Object();
cmi.student_data = new Object();
cmi.student_preference = new Object();
cmi.interactions = new Object();

var nav = new Object();
var element = new Object();
for (element in datamodel) {
    if (element.match(/\.n\./) == null) {
        if ((typeof eval('datamodel["' + element + '"].defaultvalue')) != 'undefined') {
            eval(element + ' = datamodel["' + element + '"].defaultvalue;');
        } else {
            eval(element + ' = "";');
        }
    }
}

export default class Storage {
    static setItem(element, value) {

        var scorm = {
            "Initialized": true,
            "cmi.suspend_data": window.localStorage.getItem('suspendData')
        }
        //scorm["cmi.suspend_data"] = window.localStorage.getItem('suspendData')
        let item = window.localStorage.getItem('scormData');
        if (!item) {
            window.localStorage.setItem('scormData', JSON.stringify(scorm));
           // window.localStorage.setItem('scormData', '{}');
        }
        item = window.localStorage.getItem('scormData');
        item = JSON.parse(item);
        item[element] = value;
        window.localStorage.setItem('scormData', JSON.stringify(item));
    }

    static getItem(element) {
        let item = window.localStorage.getItem('scormData');
        if (!item) {
            return null;
        }
        item = JSON.parse(item);
        let _return = item[element] || null;
        return _return;
    }

    static getAll() {
        let item = window.localStorage.getItem('scormData');
        if (!item) {
            return "";
        }
        item = JSON.parse(item);
        return item || "";
    }

    static clearAll() {
        window.localStorage.removeItem('scormData');
    }
}