import React from "react";
import { Grid } from "@material-ui/core";
import Cognisphere from "../../assets/images/Cognisphere.png";
import "./Contact.scss";

export default function Contact() {
  return (
    <div className="bg-landing-page">
      <img
        src={Cognisphere}
        alt="Cognisphere"
        className="contact-cognisphere"
      />
      <Grid container className="">
        <Grid
          item
          xs={12}
          sm={12}
          className="leap-lr--project-block d-flex jc-flex-start"
        >
          <div className="leap-lr--contact">
            <p className="leap-lr-project-name">Contact Us :-</p>
          </div>
        </Grid>
        <Grid
          container
          item
          className="leap-lr--project-block d-block jc-flex-start">
          <p className="leap-lr--project-description ml-70px">
            Published by Siemens 2022
          </p>
          <p className="leap-lr--project-description ml-70px mb-0">
            Contact User
          </p>
          <p className="leap-lr--project-description ml-70px mb-0">Division</p>
          <p className="leap-lr--project-description ml-70px mb-0">
            Phone:- 
          </p>
          <p className="leap-lr--project-description ml-70px mb-0">
            <a href="mailto:" className="">
              <span>E-mail:-</span>
              <span className="">&nbsp;</span>
              <span className="contact-mail-desc"></span>
            </a>
          </p>
        </Grid>
      </Grid>
    </div>
  );
}
