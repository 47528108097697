import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { BrowserRouter } from "react-router-dom";
import { LoginRoute } from "./Routing/LoginRoute";
import { MasterRoute, PartnerRoute, UserRoute } from "./Routing/MasterRoute";
import Header from "../src/SharedComponents/Elements/Header/Header";
import Loader from "../src/SharedComponents/Elements/Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import { storeUserDetailsAction, saveAccessTokenAction } from "../src/Redux/actions";
import { Link } from "react-router-dom";
import IdleTimer from "react-idle-timer";
import "./assets/breakpoint.scss";
import "./App.css";
import LoginPage from "../src/Pages/LoginRegister/LoginPage";

import {
    authenticateUserBasedOnValidToken,
    validateTheUserBasedOnApi,
} from "./Redux/API/api_Login";
import { UnAuthRoute } from "./Routing/UnAuthorizedRoute";
import UnauthorizedAccess from "./Pages/UnauthorizedAccess";
import {
    Button,Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";
import { Home } from "@material-ui/icons";
import { useMsal } from '@azure/msal-react';

export default function App() {
    const auth = useAuth();
    const dispatch = useDispatch();
    //const [isAuthorized, setIsAuthorized] = useState(null);
    const [isLoader, setIsLoader] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const isOTPVerified = useSelector((state) => state.isVerifiedOTPReducer);
    const handleClose = () => {
        setDialogOpen(false);
    };
    const { instance, accounts } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const [isAuthorized, setIsAuthorized] = useState(true);
    function sessionExpire(auth, dispatch) {
        var newurl =
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname;
        window.history.pushState({ path: newurl }, "", `/`);
        localStorage.clear();
        sessionStorage.clear();
        if (auth !== undefined) {
            dispatch(storeUserDetailsAction(""));

            caches.keys().then((names) => {
                names.forEach((name) => {
                    caches.delete(name);
                });
            });
        }
        window.location.href = "/";
    }

    //function authenticateUserWithGID(gid) {
    //    authenticateUserBasedOnValidToken(dispatch, token, gid)
    //        .then((response) => {
    //            if (response) {
    //                setDialogOpen(false);
    //                setIsLoader(false);
    //            } else {
    //                setIsLoader(false);
    //                setDialogOpen(true);
    //            }
    //        })
    //        .catch((error) => {
    //            setIsLoader(false);
    //            setDialogOpen(true);
    //        });
    //}
    function authenticateUserWithGID(gid) {
        authenticateUserBasedOnValidToken(dispatch, activeAccount.idToken, gid)
            .then((response) => {
                if (response) {
                    console.log("app", response)
                   // dispatch(saveAccessTokenAction(activeAccount.idToken));
                    setIsLoader(false);
                    setIsAuthorized(false);
                } else {
                    setIsLoader(false);
                    setIsAuthorized(false);
                    setDialogOpen(true);
                }
            })
            .catch((error) => {
                //setDialogOpen(true);
                setIsLoader(false);
            });
    }

    //useEffect(() => {
    //    if (auth.user) {
    //        setIsLoader(true);
    //        if (token) {
    //            const arr = auth.user.profile.sub.split("|");
    //            let gid = arr && arr.length ? arr[1] : "";
    //            authenticateUserWithGID(gid);
    //        } else {
    //            validateTheUserBasedOnApi(dispatch, auth)
    //                .then((response) => {
    //                    setIsLoader(false);
    //                })
    //                .catch((error) => {
    //                    setIsLoader(false);
    //                });
    //        }
    //    }
    //}, [auth, token, dispatch]);
    useEffect(() => {
        console.log(activeAccount);
        if (activeAccount && isAuthorized && !userDetails.userId) {
            setIsLoader(true);
            dispatch(saveAccessTokenAction(activeAccount.idToken));
            //dispatch(saveAzureTokenAction(activeAccount.idToken));
            authenticateUserWithGID();
        }
        else {

            setIsLoader(false);

        }


    }, [instance, activeAccount, !userDetails.userId]);

    return (
        <BrowserRouter>
            {auth.isLoading || isLoader ? (
                <Loader />
            ) : (userDetails && userDetails.roleId == 1) || auth.user ? (
                <div className="potts-master-block">
                    <Header>Header Component</Header>
                    <main className="">{MasterRoute}</main>
                </div>
                ) :
                    (userDetails && userDetails.roleId == 2) ? (
                        <div className="potts-master-block">
                            <Header>Header Component</Header>
                            <main className="">{PartnerRoute}</main>
                        </div>
                ) :
                    (userDetails && userDetails.roleId == 3) ? (
            <div className="potts-master-block">
                <Header>Header Component</Header>
                <main>{UserRoute}</main>
            </div>
                    ) :(
                <div className="potts-master-block">
                    <main className="">{LoginRoute}</main>
                </div>
            )}
            <div>
                <IdleTimer
                    timeout={1000 * 60 * 15}
                    onIdle={sessionExpire}
                    debounce={250}
                />
            </div>
            <>
                <Dialog
                    disableEscapeKeyDown
                    disableBackdropClick
                    open={dialogOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Error</DialogTitle>
                    <DialogContent>
                        <UnauthorizedAccess />
                    </DialogContent>
                    <DialogActions className="error-popup">
                        <Grid item xs={4}>
                            <Button className="leap-button--tertiary" onClick={handleClose}>
                                &lt;&lt; Close
                            </Button>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </>
        </BrowserRouter>
    );
}
