import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Grid,
    Box,
    Link,
    Button,
    TextField,
    Container,
    InputAdornment,
    DialogContentText,
    IconButton,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import "../LoginRegister/LoginRegister.scss";
import OfficialLogo from "../../assets/images/siemens-logo-white.svg";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "react-oidc-context";
import { Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, FormControl, Select, InputLabel } from "@material-ui/core";
import { baseApiURL } from "../../Utilities/utility";
import {
    validateTheUserBasedOnApi,
} from "../../Redux/API/api_Login";
import {
    storeUserDetailsAction,
    isVerifiedOTPAction,
    saveExternalEmailAction
} from "../../Redux/actions"
import { useForm, Controller } from "react-hook-form";
import SnackbarCustom from "../../SharedComponents/common/Snackbar/Snackbar";
import Footer from "../../SharedComponents/common/footer";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
}));


export default function LoginRegister() {
    const token = useSelector((state) => state.saveTokenReducer.token);
    const auth = useAuth();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailHelper, setEmailHelper] = useState("");
    const [passwordHelper, setPasswordHelper] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [open, setOpen] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState("");
    const [dialogContent, setDialogContent] = React.useState("");
    const [userDataTemp, setUserDataTemp] = useState({});
    const { control, handleSubmit } = useForm();
    const [expiredTime, setExpiredTime] = useState(Date.now());
    const [distributorOTP, setDistributorOTP] = useState();
    const [userOTP, setUserOTP] = useState();
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackClose = (e) => {
        setSnackOpen(false);
    };
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setSnackOpen(true);
    };
    const { instance } = useMsal();
    const loginDetailsChange = (event) => {
        let valid;
        switch (event.target.id) {
            case "supplierEmail":
                setEmail(event.target.value);
                valid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                    event.target.value
                );
                if (!valid) {
                    setEmailHelper("Invalid Email");
                } else {
                    setEmailHelper("");
                }
                break;
            case "password":
                setPassword(event.target.value);
                if (event.target.value.length < 7) {
                    setPasswordHelper("Minimum 8 characters");
                } else {
                    setPasswordHelper("");
                }
                break;

            default:
                break;
        }
    };


    const ResendOTP = (e) => {

        setDialogOpen(false);
        let userData = {
            userEmailId: email,
            password: password,
        };
        externalUserLogin(userData);
    };

    const handleDialogOpen = (content, title) => {
        setDialogTitle(title);
        setDialogContent(content);
        setDialogOpen(true);
    };

    function handleClose() {
        setDialogOpen(false);
        window.location.reload();
    }

    function handleSubmitCourse() {
        setOpen(false);
    }
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownShowPassword = () => setShowPassword(!showPassword);
    function azureADLogin() {
        instance.loginRedirect(loginRequest).catch(e => {
            console.log(e);
        });
        // dispatch(auth.signinRedirect);
    }

    async function externalUserLogin(userData) {
        setIsLoader(true);
        setEmail(email);
        dispatch(saveExternalEmailAction(email));
        validateTheUserBasedOnApi(dispatch, auth, email)
            .then((responseValidate) => {
                if (responseValidate) {
                    //validateExternalUser(responseValidate, email)
                    const options = {
                        method: "post",
                        mode: "cors",
                        headers: {
                            Accept: "application/json, text/plain, */*",
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Credentials": true,
                            Authorization: "Bearer " + responseValidate,
                            "Access-Control-Allow-Origin": `${window.location.origin}`,
                        },
                        body: JSON.stringify(window.btoa(email)),
                    };

                    fetch(baseApiURL + "/Login/LoginUser", options)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data) {
                                var responseAuthenticate = data.result;
                                if (typeof responseAuthenticate === "object") {
                                    setUserDataTemp(responseAuthenticate);
                                    setIsLoader(false);
                                    setDialogOpen(true);
                                } else {
                                    if (typeof responseAuthenticate === "string") {
                                        setIsLoader(false);
                                        handleDialogOpen(
                                            responseAuthenticate,
                                            responseAuthenticate.includes("Failed")
                                                ? "Error"
                                                : "Alert"
                                        );
                                    } else {
                                        setIsLoader(false);
                                        handleDialogOpen("Invalid user data returned from server", "Error");
                                    }
                                }
                            } else {
                                setIsLoader(false);
                                handleDialogOpen("Invalid email", "Error");
                            }
                        })
                        .catch((error) => {
                            setIsLoader(false);
                            handleDialogOpen("Error in external login", "Error");
                        });
                }
            })
            .catch((error) => {
                handleDialogOpen("Error in token generation", "Error");
            });
    }

    function VerifyDistributorOTP() {
        fetch(baseApiURL + "/Login/ValidateDistributorOTP?userId=" + userDataTemp.id + "&otp=" + distributorOTP, { headers: { 'Authorization': 'Bearer ' + token } })
            .then((response) => response.json())
            .then((data) => {
                if (data.result.id !== 0) {
                    dispatch(storeUserDetailsAction(data.result));
                    dispatch(isVerifiedOTPAction(true));
                }
                else {
                    handleSnackOpen("Invalid OTP. Please try again", "Error");
                }
            })
            .catch((error) => console.log("Error:", error));
    }

    function VerifyUserOTP() {
        fetch(baseApiURL + "/Login/ValidateUserOTP?userId=" + userDataTemp.id + "&userOTP=" + userOTP
            + "&distributorId=" + userDataTemp.distributorId + "&distributorOTP=" + distributorOTP, { headers: { 'Authorization': 'Bearer ' + token } })
            .then((response) => response.json())
            .then((data) => {
                if (data.result.id !== 0) {
                    dispatch(storeUserDetailsAction(data.result));
                    dispatch(isVerifiedOTPAction(true));
                }
                else {
                    handleSnackOpen("Invalid OTP. Please try again", "Error");
                }
            })
            .catch((error) => console.log("Error:", error));
    }

    function validateExternalUser(newToken, emailId) {
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + newToken,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(window.btoa(emailId)),
        };

        fetch(baseApiURL + "/Login/LoginUser", options)
            .then((response) => response.json())
            .then((data) => {
                return data;
            })
            .catch((error) => console.log("Error:", error));
    };

    function myIDLogin() {
        dispatch(auth.signinRedirect);
    }

    return (
        <div className="bg-login-register">
            <div className={classes.root}>

                <Container maxWidth="xl">
                    <Grid item xs={12}>
                        <div className="leap-lr-logo">
                            <img src={OfficialLogo} className="official-logo" alt="Siemens" />
                        </div>
                    </Grid>
                    <Grid container className="leap-lr-mobileContainer">
                        <Grid item xs={12} sm={12} md={5} className="leap-lr--project-block">
                            <div className="leap-lr--project">
                                <p className="leap-lr-project-name">LEAP</p>
                                <span className="leap-lr--project-description">SI EP India Learning Portal</span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={10} md={4} className="pt-lr-master-block">
                            <div className="pt-lr-block">
                                <div className="pt-login-siemens_user">
                                    <h3 className="m-0">Siemens Employee Login </h3>
                                    <span>If you are a Siemens Employee, login via Azure AD</span>
                                    <div className="d-flex jc-center myid-login-btn-block">
                                        <Link to="/">
                                            <Button
                                                className="leap-button--hero myid-login-btn"
                                                id="myIDLoginBtn"
                                                onClick={azureADLogin}
                                            //onClick={auth.signinRedirect}
                                            >
                                                <span>AD Login</span>
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="pt-divider">
                                    <hr className="dashed" />
                                </div>
                                <div className="pt-login-external_user">
                                    <h3 className="m-0">External Login</h3>
                                    <span>
                                        If you are an external employee, login via registered Email Id
                                    </span>
                                    {/* <p className={classes.errorMessage}>{errMessage}</p> */}
                                    <TextField
                                        type="email"
                                        label="Email"
                                        fullWidth
                                        value={email}
                                        onChange={loginDetailsChange}
                                        error={emailHelper.length !== 0}
                                        helperText={emailHelper}
                                        variant="filled"
                                        id="supplierEmail"
                                        className="leap-login-form-input mt-1"
                                    />
                                    <div className="d-flex jc-center supplier-login-btn-block">
                                        <Button
                                            type="submit"
                                            className="leap-button--hero supplier-login-btn"
                                            id="SupplierLoginBtn"
                                            onClick={externalUserLogin}
                                            disabled={
                                                email.length === 0 ||
                                                emailHelper.length !== 0
                                            }
                                        >
                                            Generate OTP
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    
                    <Footer />

                    <div>
                        {dialogContent ? (
                            <Dialog
                                open={dialogOpen}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        {dialogContent}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={handleClose}
                                        type="input"
                                        className="pt-button--secondary supplier-login-btn"
                                        id="SupplierOTPResend"
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        ) : userDataTemp.roleId == 2 ? (
                            <Dialog
                                disableEscapeKeyDown
                                disableBackdropClick
                                maxWidth="sm"
                                open={dialogOpen}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                className="width-100"
                            >
                                <DialogTitle id="alert-dialog-title">OTP Authentication</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <Controller
                                            name="otp"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: "Valid OTP required",
                                                minLength: {
                                                    value: 6,
                                                    message: "OTP must be of 6 characters",
                                                },
                                                maxLength: {
                                                    value: 6,
                                                    message: "OTP must be of 6 characters",
                                                },
                                                validate: (value) =>
                                                    value !== userDataTemp.generatedOTP
                                                        ? "OTP did not match"
                                                        : true,
                                            }}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    fullWidth
                                                    type="text"
                                                    label="Enter OTP"
                                                    variant="filled"
                                                    value={distributorOTP ? distributorOTP.replace(/\D/g, "") : ""}
                                                    inputProps={{ maxLength: 6 }}
                                                    onChange={(e) => {
                                                        setDistributorOTP(e.target.value);
                                                    }}
                                                    error={!!error}
                                                    helperText={error ? error.message : null}
                                                    id="supplierOTP"
                                                    className="pt-login-form-input"
                                                />
                                            )}
                                        />
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={ResendOTP}
                                        type="input"
                                        className="leap-button--secondary supplier-login-btn"
                                        id="SupplierOTPResend"
                                    >
                                        Resend
                                    </Button>
                                    <Button
                                        onClick={VerifyDistributorOTP}
                                        type="submit"
                                        className="leap-button--primary supplier-login-btn"
                                        id="SupplierOTPVerify"
                                    >
                                        Verify
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        )
                            : (
                                <Dialog
                                    disableEscapeKeyDown
                                    disableBackdropClick
                                    maxWidth="sm"
                                    open={dialogOpen}
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    className="width-100"
                                >
                                    <DialogTitle id="alert-dialog-title">OTP Authentication</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            <Controller
                                                name="otp"
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                    required: "Valid OTP required",
                                                    minLength: {
                                                        value: 6,
                                                        message: "OTP must be of 6 characters",
                                                    },
                                                    maxLength: {
                                                        value: 6,
                                                        message: "OTP must be of 6 characters",
                                                    },
                                                    validate: (value) =>
                                                        value !== userDataTemp.generatedOTP
                                                            ? "OTP did not match"
                                                            : true,
                                                }}
                                                render={({
                                                    field: { onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <TextField
                                                        fullWidth
                                                        type="text"
                                                        label="Enter User OTP"
                                                        variant="filled"
                                                        value={userOTP ? userOTP.replace(/\D/g, "") : ""}
                                                        inputProps={{ maxLength: 6 }}
                                                        onChange={(e) => {
                                                            setUserOTP(e.target.value);
                                                        }}
                                                        error={!!error}
                                                        helperText={error ? error.message : null}
                                                        id="supplierOTP"
                                                        className="pt-login-form-input"
                                                    />
                                                )}
                                            />
                                            <Controller
                                                name="distributorOtp"
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                    required: "Valid OTP required",
                                                    minLength: {
                                                        value: 6,
                                                        message: "OTP must be of 6 characters",
                                                    },
                                                    maxLength: {
                                                        value: 6,
                                                        message: "OTP must be of 6 characters",
                                                    },
                                                    validate: (value) =>
                                                        value !== userDataTemp.generatedOTP
                                                            ? "OTP did not match"
                                                            : true,
                                                }}
                                                render={({
                                                    field: { onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <TextField
                                                        fullWidth
                                                        type="text"
                                                        label="Enter Partner OTP"
                                                        variant="filled"
                                                        value={distributorOTP ? distributorOTP.replace(/\D/g, "") : ""}
                                                        inputProps={{ maxLength: 6 }}
                                                        onChange={(e) => {
                                                            setDistributorOTP(e.target.value);
                                                        }}
                                                        error={!!error}
                                                        helperText={error ? error.message : null}
                                                        id="supplierOTP"
                                                        className="pt-login-form-input"
                                                    />
                                                )}
                                            />
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={ResendOTP}
                                            type="input"
                                            className="leap-button--secondary supplier-login-btn"
                                            id="SupplierOTPResend"
                                        >
                                            Resend
                                        </Button>
                                        <Button
                                            onClick={VerifyUserOTP}
                                            type="submit"
                                            className="leap-button--primary supplier-login-btn"
                                            id="SupplierOTPVerify"
                                        >
                                            Verify
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            )}
                    </div>

                    <SnackbarCustom
                        open={snackOpen}
                        message={snackMessage}
                        alertType={alertType}
                        handleClose={handleSnackClose}
                    />
                </Container>
            </div>


        </div>
    );
}
